<template>
  <div>
    <PageDynamicBreadcrumb />

    <PageHeader title="Business Creator" />

    <BusinessForm
      type="create"
      :submitting="submitting"
      @submit="createBusiness"
    />
  </div>
</template>

<script>
import BusinessForm from "@/components/BusinessForm";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";

export default {
  name: "BusinessCreator",
  components: {
    PageDynamicBreadcrumb,
    PageHeader,
    BusinessForm
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    createBusiness(business) {
      this.submitting = true;
      this.$store
        .dispatch("businesses/POST_REQUEST", {
          endpoint: "create",
          params: {
            name: business.name
          }
        })
        .then(() => {
          this.triggerActionOutcomeAlert("create", true);
          this.$router.push({ path: "/manage/businesses" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("create", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
